import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

interface LoadingSpinnerProps {
  height?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ height = 'full' }) => (
  <Flex w="full" h={height} justifyContent="center" alignItems="center">
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="honey.500"
      size="xl"
    />
  </Flex>
);

export default LoadingSpinner;
