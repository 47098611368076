import React from 'react';
import LoadingSpinner from '@/components/LoadingSpinner';

const centerStyle: React.CSSProperties = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
};

const SuspenseLoader = () => (
  <div style={centerStyle}>
    <LoadingSpinner />
  </div>
);

export default SuspenseLoader;
