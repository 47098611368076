import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@/components/Utilities/ErrorFallBack';
import PrivateRoute from '@/routes/PrivateRoute';

// Dynamic imports
const LandingPage = React.lazy(() => import('@/pages/LandingPage'));
const SearchBarLayout = React.lazy(() => import('@/pages/SearchBarLayout'));
const Studios = React.lazy(() => import('@/pages/Studios'));
const StudioDetailPage = React.lazy(() => import('@/pages/StudioDetailPage'));
const StripeForm = React.lazy(
  () => import('@/components/BookingConfirmation/StripeForm'),
);
const BasicLayout = React.lazy(() => import('@/pages/Authentication'));
const PrivacyPolicyPage = React.lazy(() => import('@/pages/PrivacyPolicyPage'));
const ConfirmationPage = React.lazy(() => import('@/pages/ConfirmationPage'));
const CreateStudioConfirmation = React.lazy(
  () => import('@/components/CreateStudio/CreateStudioConfirmation'),
);
const StudioFormPage = React.lazy(() => import('@/pages/StudioFormPage'));
const NavbarLayout = React.lazy(() => import('@/pages/NavbarLayout'));
const ListYourStudioCTAPage = React.lazy(
  () => import('@/pages/ListYourStudioCTAPage'),
);
const Dashboard = React.lazy(() => import('@/pages/Dashboard'));
const BookingPage = React.lazy(
  () => import('@/components/DashBoard/ManageReservations/BookingPage'),
);
const Index = React.lazy(() => import('@/components/DashBoard/UserProfile'));
const ChangePassword = React.lazy(
  () => import('@/components/DashBoard/ChangePassword'),
);
const ManageStudios = React.lazy(
  () => import('@/components/DashBoard/ManageStudios'),
);
const PaymentSettings = React.lazy(
  () => import('@/components/DashBoard/PaymentSettings'),
);
const PaymentSettingsCallback = React.lazy(
  () => import('@/components/DashBoard/PaymentSettingsCallback'),
);
const ManageStudioReservations = React.lazy(
  () => import('@/components/DashBoard/ManageStudioReservations'),
);
const BookingImport = React.lazy(
  () => import('@/components/DashBoard/BookingImport'),
);
const SignInForm = React.lazy(
  () => import('@/components/Authentication/SignInForm'),
);
const SignUpForm = React.lazy(
  () => import('@/components/Authentication/SignUpForm'),
);
const ForgotPasswordForm = React.lazy(
  () => import('@/components/Authentication/ForgotPasswordForm'),
);
const ResetPasswordForm = React.lazy(
  () => import('@/components/Authentication/ResetPasswordForm'),
);

// Router
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LandingPage />
      </ErrorBoundary>
    ),
  },
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SearchBarLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/studios',
        element: <Studios />,
      },
      {
        path: '/studios/:id',
        element: <StudioDetailPage />,
      },
      {
        path: '/stripe',
        element: <StripeForm />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BasicLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: '/studios/:id/confirmation',
        element: (
          <PrivateRoute>
            <ConfirmationPage />
          </PrivateRoute>
        ),
      },
      {
        path: '/studios/new/confirmation',
        element: (
          <PrivateRoute>
            <CreateStudioConfirmation />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NavbarLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: 'list-your-studio',
        element: <ListYourStudioCTAPage />,
      },
      {
        path: '/studios/new',
        element: (
          <PrivateRoute>
            <StudioFormPage />
          </PrivateRoute>
        ),
      },
      {
        path: '/studios/edit/:id',
        element: (
          <PrivateRoute>
            <StudioFormPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Dashboard />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/dashboard/bookings',
        element: <BookingPage />,
      },
      {
        path: '/dashboard/user_profile_edit',
        element: <Index />,
      },
      {
        path: '/dashboard/change_password',
        element: <ChangePassword />,
      },
      {
        path: '/dashboard/manage_studios',
        element: <ManageStudios />,
      },
      {
        path: '/dashboard/payment_settings',
        element: <PaymentSettings />,
      },
      {
        path: '/dashboard/payment_settings/callback',
        element: <PaymentSettingsCallback />,
      },
      {
        path: '/dashboard/manage_reservations',
        element: <ManageStudioReservations />,
      },
      {
        path: '/dashboard/booking_import',
        element: <BookingImport />,
      },
    ],
  },
  {
    path: 'auth',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BasicLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: 'sign_in',
        element: <SignInForm />,
      },
      {
        path: 'sign_up',
        element: <SignUpForm />,
      },
      {
        path: 'forgot_password',
        element: <ForgotPasswordForm />,
      },
      {
        path: 'reset_password',
        element: <ResetPasswordForm />,
      },
    ],
  },
]);

export default router;
